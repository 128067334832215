"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProductAssertionBatchMeasuresInput = exports.getProductAssertionBatchLabelsInput = exports.getProductAssertionBatchCategoriesInput = exports.BulkAssertionOperationTypes = void 0;
var graphql_1 = require("../../../../__generated__/graphql");
var BulkAssertionOperationTypes;
(function (BulkAssertionOperationTypes) {
    BulkAssertionOperationTypes["Replace"] = "replace";
    BulkAssertionOperationTypes["Merge"] = "merge";
    BulkAssertionOperationTypes["Delete"] = "delete";
    BulkAssertionOperationTypes["DeleteAll"] = "deleteAll";
})(BulkAssertionOperationTypes = exports.BulkAssertionOperationTypes || (exports.BulkAssertionOperationTypes = {}));
var getAssertionTypes = function (operationType) {
    switch (operationType) {
        case BulkAssertionOperationTypes.Replace:
            return [graphql_1.ProductAttributeAssertionType.Exclusive, graphql_1.ProductAttributeAssertionType.Positive];
        case BulkAssertionOperationTypes.Merge:
            return [graphql_1.ProductAttributeAssertionType.Positive, graphql_1.ProductAttributeAssertionType.Positive];
        case BulkAssertionOperationTypes.Delete:
            return [graphql_1.ProductAttributeAssertionType.Negative, graphql_1.ProductAttributeAssertionType.Negative];
        case BulkAssertionOperationTypes.DeleteAll:
            return [graphql_1.ProductAttributeAssertionType.Exclusive];
    }
};
var getProductAssertionBatchCategoriesInput = function (category) { return [{
        category: category,
        type: graphql_1.ProductAttributeAssertionType.Exclusive,
    }]; };
exports.getProductAssertionBatchCategoriesInput = getProductAssertionBatchCategoriesInput;
var getProductAssertionBatchLabelsInput = function (operationType, attributeId, values) {
    if (values === void 0) { values = []; }
    var _a = __read(getAssertionTypes(operationType), 2), firstElementAssertionType = _a[0], restElementsAssertionType = _a[1];
    if (!restElementsAssertionType) {
        return [{
                label: attributeId,
                type: firstElementAssertionType,
                value: null,
            }];
    }
    var _b = __read(values), firstElement = _b[0], restElements = _b.slice(1);
    var firstLabel = {
        label: attributeId,
        type: firstElementAssertionType,
        value: firstElement,
    };
    var labels = restElements.map(function (value) { return ({
        label: attributeId,
        type: restElementsAssertionType,
        value: value,
    }); });
    return __spreadArray([firstLabel], __read(labels), false);
};
exports.getProductAssertionBatchLabelsInput = getProductAssertionBatchLabelsInput;
var getProductAssertionBatchMeasuresInput = function (operationType, attributeId, values) {
    if (values === void 0) { values = []; }
    var _a = __read(getAssertionTypes(operationType), 2), firstElementAssertionType = _a[0], restElementsAssertionType = _a[1];
    if (!restElementsAssertionType) {
        return [{
                dimension: attributeId,
                type: firstElementAssertionType,
                value: null,
            }];
    }
    var _b = __read(values), firstElement = _b[0], restElements = _b.slice(1);
    var firstMeasure = {
        dimension: attributeId,
        type: firstElementAssertionType,
        unit: firstElement.unit,
        value: firstElement.value,
    };
    var measures = restElements.map(function (_a) {
        var unit = _a.unit, value = _a.value;
        return ({
            dimension: attributeId,
            type: restElementsAssertionType,
            unit: unit,
            value: value,
        });
    });
    return __spreadArray([firstMeasure], __read(measures), false);
};
exports.getProductAssertionBatchMeasuresInput = getProductAssertionBatchMeasuresInput;
