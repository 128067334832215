"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseFileData = void 0;
var ramda_1 = require("ramda");
var attributes_1 = require("./client/attributes");
var proposal_1 = require("./client/proposal");
var constants_1 = require("./client/proposal/hooks/constants");
var upload_file_options_1 = require("./models/upload-file-options");
var utils_1 = require("./utils");
var parseFileRow = function (_a) {
    var e_1, _b, e_2, _c;
    var _d, _e;
    var row = _a.row, columnPairs = _a.columnPairs;
    var parsedAttributes = {};
    var parsedMeasures = {};
    var parsedCategories = [];
    try {
        for (var columnPairs_1 = __values(columnPairs), columnPairs_1_1 = columnPairs_1.next(); !columnPairs_1_1.done; columnPairs_1_1 = columnPairs_1.next()) {
            var _f = __read(columnPairs_1_1.value, 2), attribute = _f[0], columnWithUnit = _f[1];
            var isMeasure = !!columnWithUnit;
            var values = [].concat(row[attribute]);
            if (values.length) {
                try {
                    for (var values_1 = (e_2 = void 0, __values(values)), values_1_1 = values_1.next(); !values_1_1.done; values_1_1 = values_1.next()) {
                        var value = values_1_1.value;
                        if (isMeasure) {
                            var unit = row[columnWithUnit] || columnWithUnit;
                            if (value && unit) {
                                var parsedValue = Number(value);
                                if (!Number.isNaN(parsedValue)) {
                                    // if the measure unit in the row is empty its value will be taken from the column name
                                    parsedMeasures[attribute] = __spreadArray(__spreadArray([], __read((_d = parsedMeasures[attribute]) !== null && _d !== void 0 ? _d : []), false), [{ unit: unit, value: parsedValue }], false);
                                }
                            }
                        }
                        else if ((0, utils_1.areEqualCaseInsensitive)(attribute, attributes_1.CATEGORY_ATTRIBUTE_NAME)) {
                            parsedCategories.push(value);
                        }
                        else {
                            parsedAttributes[attribute] = __spreadArray(__spreadArray([], __read((_e = parsedAttributes[attribute]) !== null && _e !== void 0 ? _e : []), false), [value], false);
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (values_1_1 && !values_1_1.done && (_c = values_1.return)) _c.call(values_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (columnPairs_1_1 && !columnPairs_1_1.done && (_b = columnPairs_1.return)) _b.call(columnPairs_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return { parsedAttributes: parsedAttributes, parsedMeasures: parsedMeasures, parsedCategories: parsedCategories };
};
var parseFileData = function (_a) {
    var data = _a.data, columnsOrder = _a.columnsOrder, mode = _a.mode, isMeasuresEnabled = _a.isMeasuresEnabled;
    var records = [];
    var _b = __read(columnsOrder), idColumnName = _b[0], attributeNames = _b.slice(1);
    var columnPairs = (0, ramda_1.splitEvery)(isMeasuresEnabled ? 2 : 1, attributeNames);
    data.forEach(function (row) {
        // treat the first column as an id column
        var productId = row[idColumnName];
        var _a = parseFileRow({ row: row, columnPairs: columnPairs }), parsedAttributes = _a.parsedAttributes, parsedMeasures = _a.parsedMeasures, parsedCategories = _a.parsedCategories;
        var operationType = mode === upload_file_options_1.UpdateDataModes.Replace
            ? proposal_1.BulkAssertionOperationTypes.Replace
            : proposal_1.BulkAssertionOperationTypes.Merge;
        var categories = !(0, ramda_1.isEmpty)(parsedCategories)
            ? (0, constants_1.getProductAssertionBatchCategoriesInput)(parsedCategories[0])
            : null;
        var labels = !(0, ramda_1.isEmpty)(parsedAttributes)
            ? Object.entries(parsedAttributes).reduce(function (acc, _a) {
                var _b = __read(_a, 2), attribute = _b[0], values = _b[1];
                return __spreadArray(__spreadArray([], __read(acc), false), __read((0, proposal_1.getProductAssertionBatchLabelsInput)(operationType, attribute, values)), false);
            }, [])
            : null;
        var measures = !(0, ramda_1.isEmpty)(parsedMeasures)
            ? Object.entries(parsedMeasures).reduce(function (acc, _a) {
                var _b = __read(_a, 2), attribute = _b[0], valueItems = _b[1];
                return __spreadArray(__spreadArray([], __read(acc), false), __read((0, proposal_1.getProductAssertionBatchMeasuresInput)(operationType, attribute, valueItems)), false);
            }, [])
            : null;
        if ((labels === null || labels === void 0 ? void 0 : labels.length) || (measures === null || measures === void 0 ? void 0 : measures.length) || (categories === null || categories === void 0 ? void 0 : categories.length)) {
            records.push(__assign(__assign(__assign({ filter: {
                    externalId: {
                        equals: productId,
                    },
                } }, labels && { labels: labels }), measures && { measures: measures }), categories && { categories: categories }));
        }
    });
    return records;
};
exports.parseFileData = parseFileData;
