"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductsListView = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var hooks_1 = require("./hooks");
var constants_1 = require("./hooks/constants");
var use_products_table_columns_config_hook_1 = require("./hooks/use-products-table-columns-config.hook");
var products_customize_view_1 = require("./products-customize-view");
var products_tags_menu_container_1 = require("./products-tags-menu/products-tags-menu.container");
var table_1 = require("../../../../../common/table");
var attributes_1 = require("../../../../client/attributes");
var hooks_2 = require("../../../../client/hooks");
var product_1 = require("../../../../client/product");
var proposal_1 = require("../../../../client/proposal");
var hooks_3 = require("../../../../hooks");
var feature_flags_1 = require("../../../../../feature-flags");
var hooks_4 = require("../../../../../hooks");
var localization_1 = require("../../../../../localization");
var store_1 = require("../../../../../store");
var ProductsListView = function (_a) {
    var selected = _a.selected, isSelectable = _a.isSelectable, isCustomizeViewOpen = _a.isCustomizeViewOpen, search = _a.search, selectedAttributeNode = _a.selectedAttributeNode, onCompleted = _a.onCompleted, onSelect = _a.onSelect, onActiveProductIdChange = _a.onActiveProductIdChange, onCustomizeViewClose = _a.onCustomizeViewClose, setProducts = _a.setProducts;
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var selectedStrategyNodeId = (0, store_1.useSelectedTreeNodeId)();
    var featureFlagsService = (0, hooks_4.useFeatureFlagsService)().featureFlagsService;
    var isMeasureEnabled = featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.Measures);
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _b = __read((0, react_1.useState)(null), 2), cellInfo = _b[0], setCellInfo = _b[1];
    var dispatch = (0, react_redux_1.useDispatch)();
    var catalogId = (0, hooks_3.useCatalogSettings)().selectedCatalogId;
    var refetchProductAttributes = (0, hooks_2.useRefetchQueries)().refetchProductAttributes;
    var _c = __read((0, react_1.useState)(false), 2), isSavingOrReloadingRows = _c[0], setIsSavingOrReloadingRows = _c[1];
    var _d = (0, proposal_1.useBulkAssertions)({
        catalogId: catalogId,
        onCompleted: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, refetchProductAttributes(selectedStrategyNodeId)];
                    case 1:
                        _a.sent();
                        dispatch((0, store_1.EnrichAISetProductBeingReclassified)(null));
                        setIsSavingOrReloadingRows(false);
                        return [2 /*return*/];
                }
            });
        }); },
    }), addBulkAssertions = _d.addBulkAssertions, isSavingCellEdits = _d.isLoading;
    var productsTableColumnsConfig = (0, use_products_table_columns_config_hook_1.useProductsTableColumnsConfig)({
        onEdit: function (assertions) {
            setIsSavingOrReloadingRows(true);
            addBulkAssertions(assertions);
        },
        isSavingEdits: isSavingCellEdits || isSavingOrReloadingRows,
    });
    var _e = (0, hooks_1.useProductsTableConfig)({
        productsTableColumnsConfig: productsTableColumnsConfig,
        onCellClick: setCellInfo,
    }).tableColumns, columns = _e.columns, _f = _e.selection, excludedColumns = _f.excludedColumns, excludedLabelColumns = _f.excludedLabelColumns, excludedMeasureColumns = _f.excludedMeasureColumns, selectAllMode = _f.selectAllMode, columnsOrder = _e.order.columnsOrder, onChangeColumns = _e.actions.onChangeColumns;
    var productLabelFilter = (0, react_1.useMemo)(function () {
        if (!excludedLabelColumns.length) {
            return undefined;
        }
        return { label: (!selectAllMode ? { in: excludedLabelColumns } : { notIn: excludedLabelColumns }) };
    }, [excludedLabelColumns, selectAllMode]);
    var productMeasureFilter = (0, react_1.useMemo)(function () {
        if (!excludedMeasureColumns.length) {
            return undefined;
        }
        return { dimension: (!selectAllMode ? { in: excludedMeasureColumns } : { notIn: excludedMeasureColumns }) };
    }, [excludedMeasureColumns, selectAllMode]);
    var _g = (0, product_1.useGetProducts)({
        catalogId: selectedCatalogId,
        nodeId: selectedStrategyNodeId,
        attributeNode: selectedAttributeNode,
        search: search,
        productLabelFilter: productLabelFilter,
        productMeasureFilter: productMeasureFilter,
        skipMeasures: !isMeasureEnabled,
        onCompleted: onCompleted,
    }), products = _g.products, _h = _g.pagination, pageNumber = _h.pageNumber, pageSize = _h.pageSize, totalCount = _h.totalCount, onPageChange = _h.onPageChange, onPageSizeChange = _h.onPageSizeChange, isLoadingMore = _h.isLoadingMore, isLoading = _g.isLoading, error = _g.error;
    // TODO: remove this when we have a better way to handle this, probably lifting the state up
    (0, react_1.useEffect)(function () {
        if (products === null || products === void 0 ? void 0 : products.length)
            setProducts(products);
    }, [products, setProducts]);
    var parsedProducts = (0, react_1.useMemo)(function () { return products.map(function (_a) {
        var _b;
        var _c, _d, _e, _f;
        var labels = _a.labels, measures = _a.measures, categories = _a.categories, product = __rest(_a, ["labels", "measures", "categories"]);
        var productAttributes = (_c = labels.edges.reduce(function (acc, _a) {
            var _b;
            var _c;
            var node = _a.node;
            var label = node.label, value = node.value, assertedIncorrect = node.assertedIncorrect;
            var formattedValue = value === attributes_1.SpecialAttributeValue.NotApplicable ? t({ key: 'NOT_APPLICABLE_SHORT' }) : value;
            if (assertedIncorrect) {
                return acc;
            }
            return __assign(__assign({}, acc), (_b = {}, _b[label] = __spreadArray(__spreadArray([], __read((_c = acc[label]) !== null && _c !== void 0 ? _c : []), false), [formattedValue], false), _b));
        }, {})) !== null && _c !== void 0 ? _c : {};
        var productMeasures = (_d = measures === null || measures === void 0 ? void 0 : measures.edges.reduce(function (acc, _a) {
            var _b;
            var _c;
            var node = _a.node;
            var dimension = node.dimension, value = node.value;
            return __assign(__assign({}, acc), (_b = {}, _b[dimension] = __spreadArray(__spreadArray([], __read((_c = acc[dimension]) !== null && _c !== void 0 ? _c : []), false), [value], false), _b));
        }, {})) !== null && _d !== void 0 ? _d : {};
        var classificationProductType = (_f = (_e = categories.edges) === null || _e === void 0 ? void 0 : _e[0].node) === null || _f === void 0 ? void 0 : _f.category;
        return __assign((_b = {}, _b[constants_1.LABEL_PATH] = productAttributes, _b[constants_1.MEASURE_PATH] = productMeasures, _b[constants_1.CustomProductFields.ClassificationProductType] = classificationProductType, _b), product);
    }); }, [products, t]);
    var handleSelect = function (newSelected) {
        var selectedIds = newSelected.map(function (_a) {
            var id = _a.id;
            return id;
        });
        onSelect(selectedIds);
    };
    var selectedItems = (0, react_1.useMemo)(function () { return parsedProducts.filter(function (_a) {
        var id = _a.id;
        return selected.includes(id);
    }); }, [parsedProducts, selected]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(table_1.Table, { isSelectable: isSelectable, columns: columns, data: parsedProducts, selected: selectedItems, setSelected: handleSelect, pageNumber: pageNumber, rowsPerPage: pageSize, total: totalCount, isLoading: isLoading || isLoadingMore, error: error === null || error === void 0 ? void 0 : error.message, setPerPage: onPageSizeChange, setPage: onPageChange, onRowClick: function (row) { return onActiveProductIdChange(row.id); } }),
        isCustomizeViewOpen && (react_1.default.createElement(products_customize_view_1.ProductCustomizeViewContainer, { excludedColumns: excludedColumns, columnsOrder: columnsOrder, selectAllMode: selectAllMode, columnsConfig: productsTableColumnsConfig, onChangeColumns: onChangeColumns, onClose: onCustomizeViewClose })),
        cellInfo && (react_1.default.createElement(products_tags_menu_container_1.ProductsTagsMenuContainer, { cellInfo: cellInfo, onClose: function () { return setCellInfo(null); } }))));
};
exports.ProductsListView = ProductsListView;
exports.default = exports.ProductsListView;
